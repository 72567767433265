// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
// ActiveStorage.start()

import 'bootstrap';
import '../stylesheets/application.scss';
// import * as ActiveStorage from "@rails/activestorage"

Rails.start();

const svgs = require.context('../fonts', true);
const svgPath = (name) => svgs(name, true);

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

window.onload = () => {
  // サイドメニューのtoggle表示
  const target = document.getElementById('nav-toggle');
  if (target != null) {
    target.addEventListener('click', () => {
      document.getElementById('db-wrapper').classList.toggle('toggled');
    });
  }
  const targets = document.getElementsByClassName('nav-toggle2');
  for (let i = 0; i < targets.length; i += 1) {
    const t = targets[i];
    t.addEventListener('click', () => {
      document.getElementById('db-wrapper').classList.toggle('toggled2');
      for (let j = 0; j < targets.length; j += 1) {
        const disp = targets[j].style.display;
        if (disp === 'block') {
          targets[j].style.display = 'none';
        } else if (disp === 'none') {
          targets[j].style.display = 'block';
        }
      }
    });
  }
};
